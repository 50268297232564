@media print {
    #header {
        display: none;
    }

    html,
    body {
        height: 100vh;
    }
}

@page {
    margin: 50px;
    margin-bottom: 20mm;
}