.card-container {
  margin-bottom: 0px;
  padding: 10px 150px;
  border: 1px solid #e9ecef;
  background-color: #fff;
}

.colon {
  width: fit-content;
  padding: 0px;
}

.label {
  width: 110px;
  padding-right: 0px;
}

.title-header{
  font-size: 18px;
  font-weight: 700;
  color: #016fbe;
  margin-bottom: 20px;
}

.value {
  width: fit-content;
  color: #016fbe;
  margin-left: 10px;
}

.row-info {
  display: flex;
  margin-left: 20px;
  font-size: 15px;
  font-weight: 300;
}

.info-course{
    display: flow;
    margin-bottom: 30px;
}

.button{
  background-color: #016fbe;
  color: white;
}

